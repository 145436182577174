import React from 'react';
import PropTypes from 'prop-types';

import MarkdownContent from '../utils/MarkdownContent';

const LegalPageTemplate = ({ title, content, isPreview }) => (
  <main id="container" className="container">
    <article className="mb-6">
      <h1 className="has-text-centered title is-1">{title}</h1>
      <MarkdownContent content={content} isPreview={isPreview} />
    </article>
  </main>
);

LegalPageTemplate.defaultProps = {
  isPreview: false
};

LegalPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  isPreview: PropTypes.bool
};

export default LegalPageTemplate;
