import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper/Wrapper';
import LegalPageTemplate from '../components/Templates/LegalPageTemplate';
import SEO from '../components/SEO';

const LegalPage = ({ data }) => (
  <Wrapper>
    <SEO />
    <LegalPageTemplate title={data.markdownRemark.frontmatter.title} content={data.markdownRemark.html} />
  </Wrapper>
);

LegalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.node.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default LegalPage;

export const query = graphql`
  query LegalPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
